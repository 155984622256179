<template lang="pug">
  div
    CCard
      CCardBody
        CRow
          CCol(sm=5)
            h4(class="card-title mb-0") Бренды

        vuecrud(host="http://wild", selectedtable = "brands", :columnsupdated="columnsupdated", :opts="opts", style="margin-top:15px")
          template(v-slot:row="binds")
            tr
              td
                div
                  img(:src="'/img/services/' + binds.row.service + '.ico'")
                  span &nbsp; {{binds.row.name}}
                div {{binds.row.path}}
              td
                div {{format(binds.row._goods)}}
              td
                div {{format(binds.row._sales)}}
              td
                div {{format(binds.row._oborot)}}
              td
                div {{format(binds.row._avgPrice)}}
              td
                div {{format(binds.row._sellers)}}

</template>

<script>

export default {
  name: 'Brands',
  data: function () {
    return {
      show: true,
      isCollapsed: true,

      opts: {
        canAdd: false,
        canEdit: false,
        canDelete: false
      }

    }
  },
  methods: {
    columnsupdated: function (cols) {

      cols.set('name', {label: "Название", hasFilter: true, });
      cols.delete('id');
      cols.delete('service');
      cols.delete('sourceID');
      cols.delete('sourceLink');

      cols.set('_sales', {label: "Продаж", hasFilter: true, });
      cols.set('_sellers', {label: "Продавцов", hasFilter: true, });
      cols.delete('_brands', {label: "Брендов", hasFilter: false, });
      cols.set('_oborot', {label: "Выручка", hasFilter: true, });
      cols.set('_goods', {label: "Товаров", hasFilter: true, });
      cols.set('_avgPrice', {label: "Ср. чек", hasFilter: true, });


    },

    gotoItem(row) {
      this.$router.push({name: 'Brand', params:{id: row.sourceID}});
    },

    format(number) {
      return (new Intl.NumberFormat('ru-RU').format(Math.round(number))).toString();
    }

  }
}
</script>

<style scoped>
.value {
  color: blue;
  cursor: pointer;
}
.value:hover {
  text-decoration: underline;
}
</style>